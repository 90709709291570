import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Header from '../../Component/Header/Header';
import Footer from '../../Component/Footer/Footer';
import ArrowLeft from '../../assets/Image/arrow-left.svg';
import TagsInput from '../../common/TagsInput/TagsInput.js';
import { AddMealSchema } from '../../common/ValidationSchema/ValidationSchemas.js';
import { useNavigate } from 'react-router-dom';
import { get, post } from "../../Utils/Helpers/Http.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomToastContainer from '../../common/Costomcontainer/CustomToastContainer .js';
import Image from "../../assets/Image/add_img.svg"


const AddMeal = () => {
  const [ingredients, setIngredients] = useState([]);
  const [allergyContent, setAllergyContent] = useState([]);
  const [tags, setTags] = useState([]);
  const [mealData, setMealData] = useState(null);
  const [cuisines, setCuisines] = useState([]);
  const [meals, setMeals] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [totalKcal, setTotalKcal] = useState(0);

  const [inputValues, setInputValues] = useState({
    sugar: "",
    carbohydrates: "",
    proteins: "",
    fats: "",
    fiber: "",
  });


  const navigate = useNavigate()
  useEffect(() => {
    const fetchCuisines = async () => {
      try {
        const response = await get('/Meal/GetCuisineList');
        if (response && Array.isArray(response)) {
          setCuisines(response);
        } else {
          console.warn("No data found in response or response is not an array");
        }
      } catch (error) {
        console.error('Failed to fetch cuisines:', error);
      }
    };
    const fetchMeals = async () => {
      try {
        const response = await get('/Meal/GetMealTypeList');
        if (response && Array.isArray(response)) {
          setMeals(response);
        } else {
          console.warn("No data found in response or response is not an array");
        }
      } catch (error) {
        console.error('Failed to fetch cuisines:', error);
      }
    };
    fetchMeals();
    fetchCuisines();
  }, []);
  
  useEffect(() => {
    const fetchMealDetails = async () => {
      const mealId = localStorage.getItem('MealId');

      if (mealId) {
        try {
          const response = await get(`/Meal/GetMealDetailsById?mealId=${mealId}`);
          if (response) {
            console.log("get meal by id response ", response)
            setMealData(response);
            setIngredients(response.ingredients || []);
            setAllergyContent(response.allergyContentTags || []);
            setTags(response.mealTags || []);
            setImagePreview(response.fileUrl);
            
            // Initialize inputValues with existing nutritional data
            setInputValues({
              sugar: response.sugar?.toString() || "",
              carbohydrates: response.carbohydrates?.toString() || "",
              proteins: response.proteins?.toString() || "",
              fats: response.fats?.toString() || "",
              fiber: response.fiber?.toString() || "",
            });
            
            // Calculate initial total kcal
            const total =
              (Number(response.sugar || 0) * 4) +
              (Number(response.carbohydrates || 0) * 4) +
              (Number(response.proteins || 0) * 4) +
              (Number(response.fats || 0) * 9) +
              (Number(response.fiber || 0) * 2);
            
            setTotalKcal(total);
            
          } else {
            console.warn("No data found for the provided meal ID");
          }
        } catch (error) {
          console.error('Failed to fetch meal details:', error);
        }
      }
    };

    fetchMealDetails();
  }, []);
  const handleImageChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const timestamp = Date.now(); console.log("nnnnn", Date.now()); //add timestamp to make image unique

      const originalFilename = file.name;
      const filenameWithTimestamp = `${timestamp}-${originalFilename}`;
      try {
        // const presignedUrlResponse = await get(`/Meal/GeneratePresignedUrl?fileName=${file.name}`);
        const presignedUrlResponse = await get(`/Meal/GeneratePresignedUrl?fileName=${filenameWithTimestamp}`);
        console.log("presignedUrlResponse", presignedUrlResponse);

        if (presignedUrlResponse && presignedUrlResponse.data) {
          const presignedUrl = presignedUrlResponse.data;
          const imageUrl = presignedUrl.split('?')[0];
          setImageUrl(imageUrl)
          console.log("imagemmmmm", imageUrl);

          await fetch(presignedUrl, {
            method: 'PUT',
            body: file,
            headers: {
              'Content-Type': file.type
            }
          });
          const reader = new FileReader();
          reader.onload = () => {
            setImagePreview(reader.result);
          };
          reader.readAsDataURL(file);
        } else {
          throw new Error("Failed to get presigned URL");
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Update input values in state
    const newValue = value === "" ? "" : Number(value); // Convert to number
    const updatedValues = { ...inputValues, [name]: newValue };
    setInputValues(updatedValues);

    // Calculate total kcal in real-time
    const total =
      (Number(updatedValues.sugar || 0) * 4) +
      (Number(updatedValues.carbohydrates || 0) * 4) +
      (Number(updatedValues.proteins || 0) * 4) +
      (Number(updatedValues.fats || 0) * 9) +
      (Number(updatedValues.fiber || 0) * 2);

    setTotalKcal(total); // Update total kcal
  };

  const handleSubmit = async (values) => {
    const mealId = localStorage.getItem('MealId') ? parseInt(localStorage.getItem('MealId'), 10) : 0;
    console.log(mealId, "dddd");
    console.log("values", values);
    
    const payload = {
      id: mealId,
      mealTitle: values.mealTitle.trim(),
      cuisine: parseInt(values.cuisine_id, 10),
      mealType: parseInt(values.mealType, 10),
      fromPrice: parseFloat(values.priceFrom) || 0,
      toPrice: parseFloat(values.priceTo) || 0,
      fileUrl: values.image || imageUrl || null,
      calories: values.calories !== "" ? parseFloat(values.calories) : 0,
      // Use inputValues instead of values for nutritional information
      carbohydrates: inputValues.carbohydrates !== "" ? parseFloat(inputValues.carbohydrates) : 0,
      proteins: inputValues.proteins !== "" ? parseFloat(inputValues.proteins) : 0,
      fats: inputValues.fats !== "" ? parseFloat(inputValues.fats) : 0,
      fiber: inputValues.fiber !== "" ? parseFloat(inputValues.fiber) : 0,
      sugar: inputValues.sugar !== "" ? parseFloat(inputValues.sugar) : 0,
      vitamins: values.vitamins?.trim() || "",
      minerals: values.minerals?.trim() || "",
      totalCal: totalKcal || 0, // Use the calculated totalKcal
      description: values.description?.trim() || "",
      isActive: values.isActive || false,
      allergyContentTags: values.allergyContentTags || [],
      ingredients: values.ingredients || [],
      mealTags: values.mealTags || [],
    };

    try {
      console.log("payload of add update meal", payload);
      const response = await post('/Meal/AddUpdateMeal', payload);

      if (response) {
        cancel();
      } else {
        console.error("Failed to add meal", response.message);
      }
    } catch (error) {
      if (error.response) {
        toast.error("Meal fields are required")
        console.error("Error response", error.response.data);
      } else if (error.request) {
        console.error("Error request", error.request);
      } else {
        console.error("General error", error.message);
      }
      console.error("Error config", error.config);
    }
  };

  const cancel = () => {
    console.log("meal id ");
    localStorage.removeItem('MealId');
    navigate("/meal")
  }
  console.log("meal data ", mealData)

  const handleTagsChange = (tags, setFieldValue, fieldName) => {
    switch(fieldName) {
      case 'allergyContentTags':
        setAllergyContent(tags);
        setFieldValue('allergyContentTags', tags);
        break;
      case 'ingredients':
        setIngredients(tags);
        setFieldValue('ingredients', tags);
        break;
      case 'mealTags':
        setTags(tags);
        setFieldValue('mealTags', tags);
        break;
    }
  };


  const handleNumberInput = (event) => {
    const { value } = event.target;
    // Remove non-numeric characters
    const numericValue = value.replace(/[^0-9]/g, '');
    // Update the field value
    event.target.value = numericValue;
  };

  return (
    <>
      <Header />
      <div className="container">
        <Formik
          enableReinitialize
          initialValues={{
            mealTitle: mealData?.mealTitle || '',
            cuisine_id: mealData?.cuisine || '',
            mealType: mealData?.mealType || '',
            priceFrom: mealData?.fromPrice || '',
            priceTo: mealData?.toPrice || '',
            calories: mealData?.calories || '',
            carbohydrates: mealData?.carbohydrates || '',
            proteins: mealData?.proteins || '',
            fats: mealData?.fats || '',
            fiber: mealData?.fiber || '',
            sugar: mealData?.sugar || '',
            vitamins: mealData?.vitamins || '',
            minerals: mealData?.minerals || '',
            description: mealData?.description || '',
            image: mealData?.fileUrl || '',
            isActive: mealData?.isActive || false,
            totalCal: mealData?.totalCal || '',
            allergyContentTags: mealData?.allergyContentTags || [],
            ingredients: mealData?.ingredients || [],
            mealTags: mealData?.mealTags || [],
            image: mealData?.imageUrl || '',
          }}
          validationSchema={AddMealSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="row">
                <div className="com-md-12">
                  <a
                    className="backBtn"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      localStorage.removeItem('MealId');
                      navigate('/meal');
                    }}
                  >
                    <i><img src={ArrowLeft} alt="" /></i> Back
                  </a>
                </div>
                <div className="com-md-12">
                  <div className="whiteOuterBox">
                    <div className="headerWhite"> <h1>{mealData ? 'Edit Meal' : 'Add Meal'}</h1></div>
                    <div className="contentBox">
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="mb-3">
                            <label>Meal Title <span style={{ color: 'red' }}>*</span></label>
                            <Field type="text" name="mealTitle" className="form-control" placeholder="Meal Title" maxLength={50} />
                            <ErrorMessage name="mealTitle" component="div" className="error" />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="mb-3">
                            <label >Select Cuisine <span style={{ color: 'red' }}>*</span></label>
                            <Field as="select" name="cuisine_id" className="form-select">
                              <option value="" label="Select Cuisine" />
                              {cuisines?.map(cuisine => (
                                <option key={cuisine.id} value={cuisine.id}>
                                  {cuisine.name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage name="cuisine_id" component="div" className="error" />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="mb-3">
                            <label>Meal Type <span style={{ color: 'red' }}>*</span></label>
                            <Field as="select" name="mealType" className="form-select">
                              <option value="" label="Select Meal" />
                              {meals?.map(meal => (
                                <option key={meal.id} value={meal.id}>
                                  {meal.name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage name="mealType" component="div" className="error" />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="row">
                            <div className="col-lg-12">
                              <label>Price Range <span style={{ color: 'red' }}>*</span></label>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <Field type="text" name="priceFrom" className="form-control" placeholder="From" maxLength={5} onInput={handleNumberInput} />
                                <ErrorMessage name="priceFrom" component="div" className="error" />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <Field type="text" name="priceTo" className="form-control" placeholder="To" maxLength={5} onInput={handleNumberInput} />
                                <ErrorMessage name="priceTo" component="div" className="error" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <div className="mb-3">
                            <div className="uploadImageBox" style={{ position: 'relative', width: '200px', height: '200px' }}>
                              <label htmlFor="uploadImageVid" className="attachFileBox smDocumentUpload">
                                <i>
                                  <img className="uploadAddIcon" src={imagePreview || Image} alt="Upload Icon" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                </i>
                                <input
                                  type="file"
                                  id="uploadImageVid"
                                  className="attachFileHideInput"
                                  accept="image/*"
                                  onChange={handleImageChange}
                                  style={{ display: 'none' }}
                                />
                              </label>
                              <ErrorMessage name="image" component="div" className="error" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="borderTop"></div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12">
                          <h2 className="subTitle">Add Nutritional</h2>
                        </div>
                        <div className="col-lg-4">
                          <div className="mb-3">
                            <div className="smInputBoxs">
                              <label className="me-1">Sugar</label>
                              <Field type="text" name="sugar"  value={inputValues.sugar} className="form-control me-1 inputMaxWidth" placeholder="Sugar" maxLength={5} onInput={handleNumberInput} onChange={handleInputChange} />
                              <span>Gms</span>
                            </div>
                            <ErrorMessage name="sugar" component="div" className="error" />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="mb-3">
                            <div className="smInputBoxs">
                              <label className="me-1">Carbohydrates</label>
                              <Field type="text" name="carbohydrates"  value={inputValues.carbohydrates} className="form-control me-1 inputMaxWidth" placeholder="Carbohydrates" maxLength={5} onInput={handleNumberInput} onChange={handleInputChange} />
                              <span>Gms</span>
                            </div>
                            <ErrorMessage name="carbohydrates" component="div" className="error" />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="mb-3">
                            <div className="smInputBoxs">
                              <label className="me-1">Proteins</label>
                              <Field type="text" name="proteins"  value={inputValues.proteins} className="form-control me-1 inputMaxWidth" placeholder="Proteins" maxLength={5} onInput={handleNumberInput} onChange={handleInputChange} />
                              <span>Gms</span>
                            </div>
                            <ErrorMessage name="proteins" component="div" className="error" />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="mb-3">
                            <div className="smInputBoxs">
                              <label className="me-1">Fats</label>
                              <Field type="text" name="fats"  value={inputValues.fats} className="form-control me-1 inputMaxWidth" placeholder="Fats" maxLength={5} onInput={handleNumberInput} onChange={handleInputChange} />
                              <span>Gms</span>
                            </div>
                            <ErrorMessage name="fats" component="div" className="error" />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="mb-3">
                            <div className="smInputBoxs">
                              <label className="me-1">Fiber</label>
                              <Field type="text" name="fiber"  value={inputValues.fiber} className="form-control me-1 inputMaxWidth" placeholder="Fiber" maxLength={5} onInput={handleNumberInput} onChange={handleInputChange} />
                              <span>Gms</span>
                            </div>
                            <ErrorMessage name="fiber" component="div" className="error" />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="mb-3">
                            <div className="smInputBoxs">
                              <label className="me-1" style={{ fontWeight: 'bold' }}>
                                Total Kcal
                              </label>
                              <Field
                                type="text"
                                name="totalCal"
                                className="form-control me-1 inputMaxWidth"
                                value={totalKcal}
                                style={{ backgroundColor: '#88d3c3', color: '#FFFFF' }}
                                readOnly
                              />
                            </div>
                            <ErrorMessage name="totalCal" component="div" className="error" />
                          </div>
                        </div>

                        <div className="row" style={{ paddingBottom: '16px' }}>
                        <div className="col-lg-12">
                          <div className="borderTop"></div>
                        </div>
                      </div>


                        <div className="col-lg-4">
                          <div className="mb-3">
                            <div className="smInputBoxs">
                              <label>Vitamins</label>
                              <Field type="text" name="vitamins" className="form-control inputMaxWidth" placeholder="Vitamins" maxLength={50} />
                              <ErrorMessage name="vitamins" component="div" className="error" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="mb-3">
                            <div className="smInputBoxs">
                              <label>Minerals</label>
                              <Field type="text" name="minerals" className="form-control inputMaxWidth" placeholder="Minerals" maxLength={50} />
                              <ErrorMessage name="minerals" component="div" className="error" />
                            </div>
                          </div>
                        </div>
                   
                        <div className="col-lg-12">
                          <div className="mb-3">
                            <label>Allergy Content <span style={{ color: 'red' }}>*</span></label>
                            <TagsInput
                              tags={allergyContent}
                    setTags={(newTags) => handleTagsChange(newTags, setFieldValue, 'allergyContentTags')}
                              placeholder="Press Enter to add an allergy content"
                            />
                            <ErrorMessage name="allergyContent" component="div" className="error" />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label>Ingredients <span style={{ color: 'red' }}>*</span></label>
                            <TagsInput
                              tags={ingredients}
                              setTags={(newTags) => handleTagsChange(newTags, setFieldValue, 'ingredients')}
                              placeholder="Press Enter to add an ingredient"
                            />
                            <ErrorMessage name="ingredients" component="div" className="error" />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label>Tags</label>
                            <TagsInput
                              tags={tags}
                              setTags={(newTags) => handleTagsChange(newTags, setFieldValue, 'mealTags')}
                              placeholder="Press Enter to add a tag"
                            />
                            <ErrorMessage name="tags" component="div" className="error" />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-3">
                            <label>Description <span style={{ color: 'red' }}>*</span></label>
                            <Field as="textarea" name="description" className="form-control messageBox" placeholder="Description" maxLength={500} />
                            <ErrorMessage name="description" component="div" className="error" />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="mb-3 text-end">
                              <button type="button" className="btn btn-outline me-2" onClick={cancel}>
                                Cancel
                              </button>

                              <button type="submit" className="btn btn-primary">{mealData ? 'Update' : 'Save'}</button>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <CustomToastContainer />
      <Footer />
    </>
  );
};

export default AddMeal;
