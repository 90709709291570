import React from 'react';

const TagsInput = ({ tags, setTags, placeholder }) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      addTag(e.target.value);
      e.target.value = '';
    }
  };

  const handleBlur = (e) => {
    addTag(e.target.value);
    e.target.value = '';
  };

  const addTag = (value) => {
    if (value.trim() && !tags.includes(value.trim())) {
      setTags([...tags, value.trim()]);
    }
  };

  const handleRemoveTag = (index) => {
    const newTags = tags.filter((_, i) => i !== index);
    setTags(newTags);
  };

  return (
    <div className="tags-input">
      <div className="tags">
        {tags.map((tag, index) => (
          <span key={index} className="tag">
            {tag}
            <button type="button" onClick={() => handleRemoveTag(index)} className="remove-tag">x</button>
          </span>
        ))}
      </div>
      <input
        type="text"
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
      />
      <style jsx>{`
        .tags-input {
          border: 1px solid #ddd;
          border-radius: 4px;
          padding: 5px;
        }
        .tags {
          display: flex;
          flex-wrap: wrap;
          gap: 5px;
        }
        .tag {
          background-color: #76c5b4;
          border-radius: 20px; 
          padding: 3px 10px; 
          display: flex;
          align-items: center;
        }
        .remove-tag {
          background: none;
          border: none;
          color: red;
          cursor: pointer;
          margin-left: 5px;
        }
        input {
          border: none;
          outline: none;
          flex: 1;
        }
      `}</style>
    </div>
  );
};

export default TagsInput;
