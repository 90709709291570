import React, { useState, useEffect } from 'react';
import { get, post } from "../../Utils/Helpers/Http.js";
import Header from '../../Component/Header/Header';
import Inactive from '../../assets/Image/inactive.svg';
import ViewIcon from '../../assets/Image/view_icon.svg';
import Active from '../../assets/Image/s-active.svg'
import Footer from '../../Component/Footer/Footer';
import UserDetailsModal from './UserDetailModal.js';
import ConfirmStatusChangeModal from './ConfirmStatusChangeModal';
import { toast } from 'react-toastify';

const UserManagement = () => {
    const [users, setUsers] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [isUserActive, setIsIserActive] = useState(false);
    const [userToChangeStatus, setUserToChangeStatus] = useState(null);
    const [searchKeyword, setSearchKeyword] = useState('');


    useEffect(() => {
        fetchUserList();
    }, [pageNo, pageSize]);

    const fetchUserList = async () => {
        try {
            const data = await get('/User/GetUserList', {
                PageNo: pageNo,
                PageSize: pageSize,
                SearchKeyword: searchKeyword
            });
            setUsers(data?.data || []);
            // setTotalEntries(data?.total || 0);
            setTotalRecords(data?.data?.[0]?.totalRecords || 0);
        } catch (error) {
            console.error('Error fetching user list:', error);
        }
    };
    const handleSearch = () => {
        setPageNo(1); // Reset to first page on new search
        fetchUserList();
    };

    const handleViewUser = async (user) => {
        try {
            const response = await get(`/User/GetUserDetailsById?userId=${user.id}`);
            if (response.data && response.message === 'Success') {
                setSelectedUser(response.data);
                setShowModal(true);
            } else {
                console.error('Error fetching user details:', response.message);
            }
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };

    // const handleStatusChange = (user) => {
    //     console.log("user )))))))))", user)
    //     setUserToChangeStatus(user);
    //     setShowConfirmModal(true);
    //     setIsIserActive(user.isActive);
    // };
    // const confirmStatusChange = async () => {
    //     if (userToChangeStatus) {
    //         try {
    //             const payload = {
    //                 userId: userToChangeStatus.id, toUpdateStatus: !userToChangeStatus.isActive
    //             };
    //             const response = await post('/User/UpdateStatus', payload);
    //             console.log('Status updated:', response.data);
    //             setUsers((prevUsers) =>
    //                 prevUsers.map((user) =>
    //                     user.id === userToChangeStatus.id ? { ...user, isActive: !user.isActive } : user
    //                 )
    //             );
    //             setShowConfirmModal(false);
    //         } catch (error) {
    //             console.error('Error updating status:', error);
    //             toast.error("Failed to change status. Please try again.");
    //         }
    //     }
    // };

    const handlePageSizeChange = (e) => {
        const newSize = parseInt(e.target.value);
        setPageSize(newSize);
        setPageNo(1);
    };

    const getPageCount = (totalRecords, pageSize) => {
        let pages = totalRecords / pageSize;
        if (pages > 2) { return 3 }
        else if (pages > 1) { return 2 }
        else { return 1; }
    };

    return (
        <div>
            <Header />
            <div className="container">
                <div className="row">
                    <div className="com-md-12">
                        <div className="whiteOuterBox">
                            <div className="headerWhite">
                                <h1>User Management</h1>
                            </div>
                            <div className="contentBox">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search by name"
                                                value={searchKeyword}
                                                onChange={(e) => setSearchKeyword(e.target.value)}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handleSearch();
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="mb-3">
                                            <button className="btn btn-primary" onClick={handleSearch}>
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                </div>



                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Gender</th>
                                                <th>Height (in ft)</th>
                                                <th>Weight (Kg)</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users.length > 0 ? (
                                                users.map((user, index) => (
                                                    <tr key={user.id}>
                                                        <td>{(pageNo - 1) * pageSize + index + 1}</td>
                                                        <td>{user.name}</td>
                                                        <td>{user.email}</td>
                                                        <td>{user.gender}</td>
                                                        <td>{user.height}</td>
                                                        <td>{user.weight}</td>
                                                        <td>
                                                            {/* <a className="listIcons me-2" href="#" onClick={() => handleStatusChange(user)}>
                                                                <img src={user.isActive ? Active : Inactive} alt={user.isActive ? "Active" : "Inactive"} />
                                                            </a> */}
                                                            <a className="listIcons" href="#" onClick={() => handleViewUser(user)}>
                                                                <img src={ViewIcon} alt="View" />
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="7" className="text-center">No data found</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="pagination-sec d-flex align-items-center flex-wrap">

                                    <div className="numbers-pag me-auto flex showingBlog">
                                        <select
                                            className="form-filter form-select showingInput"
                                            onChange={handlePageSizeChange}
                                            value={pageSize}
                                        >
                                            <option value={5}>5</option>
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                        </select>

                                        Showing {(pageNo - 1) * pageSize + 1} to {Math.min(pageNo * pageSize, totalRecords)} of {totalRecords} entries
                                    </div>
                                    <div className="right-sec">
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination mb-0">
                                                <li className={`page-item ${pageNo === 1 ? 'disabled' : ''}`}>
                                                    <a className="page-link" href="#" onClick={() => setPageNo(pageNo - 1)}>Prev</a>
                                                </li>

                                                {[...Array(getPageCount(totalRecords, pageSize))].map((_, i) => {
                                                    const page = Math.max(1, Math.min(pageNo - 1, Math.ceil(totalRecords / pageSize) - 2)) + i;

                                                    return (
                                                        <li key={page} className={`page-item ${pageNo === page ? 'active' : ''}`}>
                                                            <a className="page-link" href="#" onClick={() => setPageNo(page)}>
                                                                {page}
                                                            </a>
                                                        </li>
                                                    );
                                                })}

                                                <li className="page-item">
                                                    <button className="page-link"
                                                        onClick={() => {
                                                            const maxPage = Math.ceil(totalRecords / pageSize);
                                                            if (pageNo < maxPage) {
                                                                setPageNo(pageNo + 1);
                                                            }
                                                        }}
                                                        disabled={pageNo >= Math.ceil(totalRecords / pageSize)}
                                                    >
                                                        Next <i className="fa-solid fa-chevron-right"></i>
                                                    </button>
                                                </li>

                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Footer />
            <UserDetailsModal
                show={showModal}
                onHide={() => setShowModal(false)}
                user={selectedUser}
            />
            {/* <ConfirmStatusChangeModal
                show={showConfirmModal}
                onHide={() => setShowConfirmModal(false)}
                onConfirm={confirmStatusChange}
                isUserActive={isUserActive}
            /> */}
        </div>
    );
};

export default UserManagement;
