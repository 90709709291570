import React, { useState, useEffect } from 'react';
import { get } from "../../Utils/Helpers/Http.js";
import Header from '../../Component/Header/Header';
import Inactive from '../../assets/Image/inactive.svg';
import Active from '../../assets/Image/s-active.svg'
import Footer from '../../Component/Footer/Footer';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const UserSubscription = () => {
    const [users, setUsers] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [planFilter, setPlanFilter] = useState(0);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [error, setError] = useState('');


    useEffect(() => {
        fetchUserList();
    }, [pageNo, pageSize, planFilter, fromDate, toDate]);

    const fetchUserList = async () => {
        try {
            const data = await get('/Subscription/GetSubscriptionList', {
                PageNo: pageNo,
                PageSize: pageSize,
                SearchKeyword: searchKeyword,
                SubscriptionType: planFilter,
                FromDate: formatToPayloadDate(fromDate),
                ToDate: formatToPayloadDate(toDate),
            });
            setUsers(data?.data || []);
            setTotalRecords(data?.data?.[0]?.totalRecords || 0);
        } catch (error) {
            console.error('Error fetching user list:', error);
        }
    };
    const handleSearch = () => {
        setPageNo(1);
        fetchUserList();
    };


    const handleReset = () => {
        setSearchKeyword('');
        setPlanFilter('0');
        setFromDate('');
        setToDate('');
        setPageNo(1);
        setError(''); // Clear validation error
        fetchUserList();
    };
    

    const handlePageSizeChange = (e) => {
        const newSize = parseInt(e.target.value);
        setPageSize(newSize);
        setPageNo(1);
    };

    const getPageCount = (totalRecords, pageSize) => {
        let pages = totalRecords / pageSize;
        if (pages > 2) { return 3 }
        else if (pages > 1) { return 2 }
        else { return 1; }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0'); // Ensure 2-digit day
        const month = date.toLocaleString('default', { month: 'short' }); // Abbreviated month (e.g., 'Dec')
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    };

    const formatToPayloadDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    const handleFromDateChange = (date) => {
        if (toDate && date > toDate) {
            setError('Start date cannot be after the end date.');
        } else {
            setError('');
            setFromDate(date);
        }
    };

    const handleToDateChange = (date) => {
        if (fromDate && date < fromDate) {
            setError('End date cannot be before the start date.');
        } else {
            setError('');
            setToDate(date);
        }
    };


    return (
        <div>
            <Header />
            <div className="container">
                <div className="row mb-3 ">
                    <div className="com-md-12">
                        <div className="whiteOuterBox">
                            <div className="headerWhite">
                                <h1>User Subscription</h1>
                            </div>
                            <div className="contentBox">
                                <div className="row filterBlog mb-2">
                                    <div className="col-lg-3">
                                        <label htmlFor="fromDate" className="form-label">Search</label>
                                        <div className="mb-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search by name"
                                                value={searchKeyword}
                                                onChange={(e) => setSearchKeyword(e.target.value)}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handleSearch();
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <label htmlFor="fromDate" className="form-label">Subscription Plan</label>
                                        <div className="mb-3">
                                            <select
                                                className="form-select"
                                                value={planFilter}
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value || '0'; // Default to '0' for "All Plans"
                                                    setPlanFilter(selectedValue);
                                                    setPageNo(1); // Reset to the first page
                                                    fetchUserList(); // Fetch data immediately after selection
                                                }}
                                            >
                                                <option value="0">All Plans</option>
                                                <option value="1">Monthly</option>
                                                <option value="2">Yearly</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                    <div className="row position-relative">
                                    <div className="col-lg-6">
                                        
                                            <label htmlFor="fromDate" className="form-label">Start Date</label>
                                            <div className="mb-3">
                                            <DatePicker
                                                selected={fromDate}
                                                onChange={handleFromDateChange}
                                                dateFormat="dd-MMM-yyyy"
                                                placeholderText="DD-MMM-YYYY"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                      
                                            <label htmlFor="toDate" className="form-label">End Date</label>
                                            <div className="mb-3">
                                            <DatePicker
                                                selected={toDate}
                                                onChange={handleToDateChange}
                                                dateFormat="dd-MMM-yyyy"
                                                placeholderText="DD-MMM-YYYY"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>

                                    {error && (
                                        <div className='errorMessageFilter'>
                                           {error}
                                        </div>
                                    )}
                                      </div>
                                      </div>
                                    <div className="col">
                                        <div className="mb-3">
                                            <button className="btn btn-primary me-3" onClick={handleSearch}>
                                                Search
                                            </button>
                                            <button className="btn btn-primary" onClick={handleReset}>
                                                Reset
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Purchased plan </th>
                                                <th>Purchased Date</th>
                                                <th>Subscription Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users.length > 0 ? (
                                                users.map((user, index) => (
                                                    <tr key={user.id}>
                                                        <td>{(pageNo - 1) * pageSize + index + 1}</td>
                                                        <td>{user.name}</td>
                                                        <td>{user.email}</td>
                                                        <td>{user.planName}</td>
                                                        <td>{formatDate(user.purchaseDate)}</td>
                                                        <td>
                                                            <a className="listIcons me-2" href="#">
                                                                <img
                                                                    src={user.isActive ? Active : Inactive}
                                                                    alt={user.isActive ? "Active" : "Inactive"}
                                                                    title={user.isActive ? "Active" : "Inactive"} // Tooltip for better clarity
                                                                />
                                                            </a>
                                                        </td>

                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="7" className="text-center">No data found</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>

                                {/* {totalRecords > 0 && ( */}

                                <div className="pagination-sec d-flex align-items-center flex-wrap">    

                                    <div className="numbers-pag me-auto flex showingBlog">
                                        <select
                                            className="form-filter form-select showingInput"
                                            onChange={handlePageSizeChange}
                                            value={pageSize}
                                        >
                                            <option value={5}>5</option>
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                        </select>

                                        Showing {totalRecords === 0 ? 0 : (pageNo - 1) * pageSize + 1} to {totalRecords === 0 ? 0 : Math.min(pageNo * pageSize, totalRecords)} of {totalRecords} entries

                                    </div>
                                    <div className="right-sec">
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination mb-0">
                                                <li className={`page-item ${pageNo === 1 ? 'disabled' : ''}`}>
                                                    <a className="page-link" href="#" onClick={() => setPageNo(pageNo - 1)}>Prev</a>
                                                </li>

                                                {[...Array(getPageCount(totalRecords, pageSize))].map((_, i) => {
                                                    const page = Math.max(1, Math.min(pageNo - 1, Math.ceil(totalRecords / pageSize) - 2)) + i;

                                                    return (
                                                        <li key={page} className={`page-item ${pageNo === page ? 'active' : ''}`}>
                                                            <a className="page-link" href="#" onClick={() => setPageNo(page)}>
                                                                {page}
                                                            </a>
                                                        </li>
                                                    );
                                                })}

                                                <li className="page-item">
                                                    <button className="page-link"
                                                        onClick={() => {
                                                            const maxPage = Math.ceil(totalRecords / pageSize);
                                                            if (pageNo < maxPage) {
                                                                setPageNo(pageNo + 1);
                                                            }
                                                        }}
                                                        disabled={pageNo >= Math.ceil(totalRecords / pageSize)}
                                                    >
                                                        Next <i className="fa-solid fa-chevron-right"></i>
                                                    </button>
                                                </li>

                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                                {/* )} */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Footer />

        </div>
    );
};

export default UserSubscription;
