import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from './Auth/Login/Login';
import ForgotPassword from './Auth/ForgotPassword/ForgotPassword';
import ResetPassword from './Auth/ResetPassword/ResetPassword';
import Error from "./ExtraPages/ErrorPage/Error";
import Dashboard from './Pages/Dashboard/Dashboard';
import UserManagement from './Pages/UserManagement/UserManagement';
import Thankyou from './ExtraPages/ThankyouPage/Thankyou';
import MealManagement from './Pages/MealManagement/MealManagement';
import Changepassword from './Pages/ChangePassword/Changepassword';
import AddMeal from './Pages/MealManagement/AddMeal';
import ProtectedRoute from './common/ProtectedRoutes/ProtectedRoute';
import InvalidToken from './ExtraPages/InvalidToken/InvalidToken';
import EmailVerification from './ExtraPages/EmailVerification/Emailverification';
import EmailVerified from './ExtraPages/EmailVerification/EmailVerified';
import EmailNotVerified from './ExtraPages/EmailVerification/EmailNotVerified';
import UserSubscription from './Pages/UserSubscription/UserSubscription';
import PrivacyPolicy from './ExtraPages/PrivacyPolicy/PrivacyPolicy';
import TermsAndCondition from './ExtraPages/TermsAndCondition/Terms';


function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/invalidtoken" element={<InvalidToken />} />
        <Route path="*" element={<Error />} />
        <Route path="/thankyou"  element={<Thankyou />} /> 
        <Route path="/emailverification"  element={<EmailVerification />} /> 
        <Route path="/emailverified"  element={<EmailVerified />} /> 
        <Route path="/emailnotverified"  element={<EmailNotVerified />} /> 
        <Route path="/privacy-policy"  element={<PrivacyPolicy />} /> 
        <Route path="/terms-condition"  element={<TermsAndCondition />} /> 

        {/* Protected Routes */}
        <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
        <Route path="/user" element={<ProtectedRoute element={<UserManagement />} />} />
        <Route path="/meal" element={<ProtectedRoute element={<MealManagement />} />} />
        <Route path="/changepassword" element={<ProtectedRoute element={<Changepassword />} />} />
        <Route path="/addmeal" element={<ProtectedRoute element={<AddMeal />} />} />
        <Route path="/user-subscription" element={<ProtectedRoute element={<UserSubscription />} />} />
      </Routes>
    </Router>
  );
}

export default App;
