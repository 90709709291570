import * as Yup from 'yup';
import 'react-toastify/dist/ReactToastify.css';

// Common password validation
const passwordValidation = Yup.string()
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{6,12}$/,
    'Invalid format.'
  )
  .required('Password is required');

// Validation schema for Reset Password
export const ResetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{6,12}$/,
      'Password must be 6-12 characters long, include at least one uppercase letter, one lowercase letter, one number, and one special character (@$!%*?&#).'
    )
    .required('New Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'The new password and confirm password should be same')
    .required('Confirm Password is required'),
});

// Validation schema for Login
export const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Email is required'),
  password: passwordValidation,
});

// Validation schema for Forgot Password
export const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Email is required'),
});

// validation schema for change password
export const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    // .matches(
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{6,12}$/,
    //   'Invalid format.'
    // )
    .required('Old Password is required'),
  newPassword: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{6,12}$/,
     'Password must be 6-12 characters long, include at least one uppercase letter, one lowercase letter, one number, and one special character (@$!%*?&#).'
    )
    .required('New Password is required')
    .test(
      'passwords-different',
      'New Password should not be the same as Old Password',
      function (value) {
        return value !== this.parent.oldPassword;
      }
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'The new password and confirm password should be same.')
    .required('Confirm Password is required'),
});

export const AddMealSchema = Yup.object().shape({
  mealTitle: Yup.string()
    .trim()
    .required('Meal Title is required')
    .max(50, 'Meal Title must be 50 characters or less'),

  mealType: Yup.number()
    .required('Meal Type selection is required')
    .typeError('Meal Type must be a number')
    .min(0, 'Meal Type must be a positive number')
    .max(99999, 'Meal Type must be 5 digits or less'),

  cuisine_id: Yup.number()
    .required('Cuisine selection is required')
    .typeError('Cuisine must be selected'),
    
  priceFrom: Yup.number()
    .required('Price From is required')
    .min(1, 'Price cannot be 0')
    .max(99999, 'Price From must be 5 digits or less'),

  priceTo: Yup.number()
    .required('Price To is required')
    .min(Yup.ref('priceFrom'), 'Price To must be greater than Price From')
    .max(99999, 'Price To must be 5 digits or less'),

  // calories: Yup.number()
  //   .required('Calories are required')
  //   .min(0, 'Calories must be positive')
  //   .max(99999, 'Calories must be 5 digits or less'),

  // carbohydrates: Yup.number()
  //   .required('Carbohydrates are required')
  //   .min(0, 'Carbohydrates must be positive')
  //   .max(99999, 'Carbohydrates must be 5 digits or less'),

  // proteins: Yup.number()
  //   .required('Proteins are required')
  //   .min(0, 'Proteins must be positive')
  //   .max(99999, 'Proteins must be 5 digits or less'),

  // fats: Yup.number()
  //   .required('Fats are required')
  //   .min(0, 'Fats must be positive')
  //   .max(99999, 'Fats must be 5 digits or less'),

  // fiber: Yup.number()
  //   .required('Fiber is required')
  //   .min(0, 'Fiber must be positive')
  //   .max(99999, 'Fiber must be 5 digits or less'),

  // sugar: Yup.number()
  //   .required('Sugar is required')
  //   .min(0, 'Sugar must be positive')
  //   .max(99999, 'Sugar must be 5 digits or less'),

  // vitamins: Yup.string()
  //   .trim()
  //   .required('Vitamins are required')
  //   .max(50, 'Vitamins must be 50 characters or less'),

  // minerals: Yup.string()
  //   .trim()
  //   .required('Minerals are required')
  //   .max(50, 'Minerals must be 50 characters or less'),

  allergyContent: Yup.array()
    .of(Yup.string().trim().max(50, 'Each Allergy Content item must be 50 characters or less'))
    .min(1, 'Allergy Content is required'),

  tags: Yup.array()
    .of(Yup.string().trim().max(50, 'Each Tag must be 50 characters or less'))
    .min(1, 'Tags are required'),

  description: Yup.string()
    .trim()
    .required('Description is required')
    .max(500, 'Description must be 500 characters or less'),
});
